.react-calendar {
  width: 100%;
  max-width: 100%;
  background: white;
  font-family: Arial, Helvetica, sans-serif;
  line-height: 1.125em;
  margin-top: 20px;
}
.react-calendar--doubleView {
  width: 100%;
}
.react-calendar--doubleView .react-calendar__viewContainer {
  display: flex;
  margin: -0.5em;
}
.react-calendar--doubleView .react-calendar__viewContainer > * {
  width: 50%;
  margin: 0.5em;
}
.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.react-calendar button {
  border: 2px solid white;
  height: auto;
  width: auto;
}
.react-calendar button:enabled:hover {
  cursor: pointer;
}
.react-calendar button:disabled:hover {
  cursor: default;
}
.react-calendar__navigation {
  display: flex;
  height: auto;
  margin-bottom: 1em;
  background-color: white;
  border-top: 0.1em solid black;
  border-bottom: 0.1em solid black;
}
.react-calendar__navigation button {
  background: white;
  color: black;
  font-weight: bold;
  font-size: 90%;
  padding: 0.5em 0.5em;
}
.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background-color: #e6e6e6;
}
.react-calendar__navigation button[disabled] {
  background-color: white;
}
.react-calendar__month-view__weekdays {
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 75%;
  background-color: white;
}
.react-calendar__month-view__weekdays__weekday {
  padding: 0.5em;
}
.react-calendar__month-view__weekNumbers {
  font-weight: bold;
}
.react-calendar__month-view__weekNumbers .react-calendar__tile {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: calc(0.75em / 0.75) calc(0.5em / 0.75);
}
.react-calendar__month-view__days__day--weekend {
  color: #0c0909;
}
.react-calendar__month-view__days__day--neighboringMonth {
  color: #757575;
}
.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
  padding: 2em 0.5em;
}
.react-calendar__tile {
  max-width: 100%;
  padding: 0.2em 0.2em;
  background: #EAEAEA;
  font-size: 90%;
  color: black;
}
.react-calendar__tile:disabled,
.react-calendar__tile--inactive{
  background-color: #EAEAEA;
}
.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus{
  opacity: 0.5;
}
.react-calendar__tile--now {
  background: white;
}
.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
  opacity: 0.5;
}
.react-calendar__tile--hasActive {
  background: #A43A89;
}
.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
  opacity: 0.5;
}
.react-calendar__tile--active {
  background: #A43A89;
}
.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  opacity: 0.5;
}
.react-calendar--selectRange .react-calendar__tile--hover {
  opacity: 0.5;
}
abbr[title], abbr[data-original-title] {
  text-decoration: none;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none;
}

.react-calendar__tile--anotherMonth{
  background-color: #EAEAEA;
  color: grey;
}
.no-birthday-cake {
  opacity: 0.25;
}
.birthday-cake {
  color: #ffc708;
  transform: translate3d(0px, -45px, 0px);
  background-color: white;
  padding: 10px 12px;
  border-radius: 50%;
  height: 100%;
  width: 15%;
  border: 2px solid #EAEAEA;
  margin-top: 10px;
  margin-bottom: -17%;
}
.react-calendar__navigation__prev-button{
  background: url('../img/calendar-left-nav.svg')
    no-repeat !important;
}
.react-calendar__navigation__next-button{
  background: url('../img/calendar-left-nav.svg')
    no-repeat !important;
  transform: rotate(180deg);
}
.react-calendar__tile--now-nobirthday {
  background: white !important;
  color: #A43A89;
  border: 1px solid #A43A89 !important;
}
.calendar-title{
  font-size: 100%;
  font-weight: bold;
  display: block;
  margin-bottom: 5%;
}
.birthday-title{
  font-size: 83%;
  opacity: 0.5;
  color: #EAEAEA;
}
.birthday-month{
  font-size: 100%;
  font-weight: bold;
  color: white;
  display: block;
}
.birthday-date{
  font-size: 210%;
  font-weight: bold;
  color: white;
}
.birthday-name{
  font-size: 90%;
  font-weight: bold;
  color: white;
  display: block;
  cursor: pointer;
}
.no-birthday-text{
  font-size: 85%;
  opacity: 0.5;
  display: inline-block;
  color: #EAEAEA;
}
.tooltip-message{
  font-size: 100%;
}
.tooltip-message:hover{
  text-decoration: underline;
  opacity: 0.5;
}