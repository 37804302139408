/* ------- CM Styles ------- */

/* Webfont, LICENSE: /webfonts/LICENSE.txt */
@font-face {
    font-family: 'fontello';
    src: url('../webfonts/fontello.eot?16131052');
    src: url('../webfonts/fontello.eot?16131052#iefix') format('embedded-opentype'),
    url('../webfonts/fontello.woff2?16131052') format('woff2'),
    url('../webfonts/fontello.woff?16131052') format('woff'),
    url('../webfonts/fontello.ttf?16131052') format('truetype'),
    url('../webfonts/fontello.svg?16131052#fontello') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'OpenSans';
    src: url('../webfonts/opensans-light-webfont.eot');
    src: url('../webfonts/opensans-light-webfont.eot?#iefix') format('embedded-opentype'),
    url('../webfonts/opensans-light-webfont.woff2') format('woff2'),
    url('../webfonts/opensans-light-webfont.woff') format('woff'),
    url('../webfonts/opensans-light-webfont.ttf') format('truetype'),
    url('../webfonts/opensans-light-webfont.svg#opensanslight') format('svg');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'OpenSans';
    src: url('../webfonts/opensans-regular-webfont.eot');
    src: url('../webfonts/opensans-regular-webfont.eot?#iefix') format('embedded-opentype'),
    url('../webfonts/opensans-regular-webfont.woff2') format('woff2'),
    url('../webfonts/opensans-regular-webfont.woff') format('woff'),
    url('../webfonts/opensans-regular-webfont.ttf') format('truetype'),
    url('../webfonts/opensans-regular-webfont.svg#opensansregular') format('svg');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'OpenSans';
    src: url('../webfonts/opensans-semibold-webfont.eot');
    src: url('../webfonts/opensans-semibold-webfont.eot?#iefix') format('embedded-opentype'),
    url('../webfonts/opensans-semibold-webfont.woff2') format('woff2'),
    url('../webfonts/opensans-semibold-webfont.woff') format('woff'),
    url('../webfonts/opensans-semibold-webfont.ttf') format('truetype'),
    url('../webfonts/opensans-semibold-webfont.svg#opensanssemibold') format('svg');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'OpenSans';
    src: url('../webfonts/opensans-bold-webfont.eot');
    src: url('../webfonts/opensans-bold-webfont.eot?#iefix') format('embedded-opentype'),
    url('../webfonts/opensans-bold-webfont.woff2') format('woff2'),
    url('../webfonts/opensans-bold-webfont.woff') format('woff'),
    url('../webfonts/opensans-bold-webfont.ttf') format('truetype'),
    url('../webfonts/opensans-bold-webfont.svg#opensansbold') format('svg');
    font-weight: 700;
    font-style: normal;
}

.sentry-error-embed-wrapper {
    z-index: 1050 !important;
}

.sentry-error-embed {
    top: 100px;
}

body 								{font-family: 'OpenSans', sans-serif; font-weight: 400; color: #3b3b3b;}

::selection 						{background: #94c73e; color: #fff;}
::-moz-selection 					{background: #94c73e; color: #fff;}


/* Colors + Shadows */

.bg-primary				  			{background-color: #3b3b3b !important;}
.bg-light							{background-color: #f5f5f5 !important;}

.bg-red-custom				  		{background-color: #f2592f !important;}
.bg-red-custom-light		  		{background-color: #fbcec1 !important;}
.bg-yellow-custom			  		{background-color: #f2be08 !important;}
.bg-yellow-custom-light		  		{background-color: #ffeeb6 !important;}
.bg-green-custom					{background-color: #94c73e !important;}
.bg-green-custom-light				{background-color: #dfeec5 !important;}
.bg-blue-custom						{background-color: #4498ba !important;}
.bg-blue-custom-light				{background-color: #d8f0fa !important;}
.bg-purple-custom					{background-color: #a34599 !important;}
.bg-purple-custom-light				{background-color: #edd8e8 !important;}
.bg-gray-custom						{background-color: #eaeaea !important;}
.bg-darkgray-custom					{background-color: #707070 !important;}

.text-primary 						{color: #3b3b3b !important;}
.text-primary a 					{color: #3b3b3b;}
.text-white							{color: #ffffff !important;}

.text-blue-custom					{color: #4498ba !important;}
.text-blue-custom a 				{color: #4498ba;}
.text-red-custom					{color: #f2592f !important;}
.text-red-custom a					{color: #f2592f;}
.text-yellow-custom					{color: #f2be08 !important;}
.text-yellow-custom a				{color: #f2be08;}
.text-green-custom					{color: #94c73e !important;}
.text-green-custom a				{color: #94c73e;}
.text-purple-custom					{color: #793472 !important;}
.text-purple-custom	a				{color: #793472;}
.text-gray-custom					{color: #d1d1d1 !important;}
.text-gray-custom a					{color: #d1d1d1}
.text-darkgray-custom				{color: #707070 !important;}
.text-darkgray-custom a				{color: #707070}

.shadow-custom						{-webkit-box-shadow: 0px 0px 10px 2px rgba(0,0,0,.2); -moz-box-shadow: 0px 0px 10px 0px rgba(0,0,0,.2); box-shadow: 0px 0px 10px 0px rgba(0,0,0,.2);}

.disabled-custom					{filter: alpha(opacity = 45); -ms-filter: "alpha(opacity = 45)"; opacity: .45;}

/* Typography */

h1, .h1										{font-size: 2.75rem; font-weight: 200; line-height: 1.299; margin-bottom: .5rem;}
h2, .h2										{font-size: 1.625rem; font-weight: 700; line-height: 1.4; margin-bottom: .75rem;}
h3, .h3										{font-size: 1.3rem; font-weight: 700; line-height: 1.4; margin-bottom: .75rem;}
h4, .h4										{font-size: 1rem; font-weight: 700; line-height: 1.4; margin-bottom: .75rem;}
h5, .h5										{font-size: 1rem; font-weight: 700; line-height: 1.4; margin-bottom: .325em;}

.text-uppercase								{letter-spacing: .0275em !important;}

small, .small								{font-size: 85%; font-weight: 400;}
.sidebar-custom	.small						{font-size: 90%}
.ref-subline								{font-size: 85%; font-weight: 400;}

b, strong									{font-weight: 700;}
.display-1									{font-weight: 200;}

/* Pagination */
.pagination-custom .page-link							{padding: .5rem .925rem;}
.pagination-custom .page-item a							{text-decoration: none;}
.pagination-custom .page-item.disabled a				{color: #dfdfdf;}
.pagination-custom .page-item .page-link 				{color: #3b3b3b; border-color: #dfdfdf;}
.pagination-custom .page-item .page-link:hover			{color: #fff; background-color: #707070;}
.pagination-custom .page-item .page-link.text-primary 	{font-weight: 600; background-color: #eaeaea;}

/* Spinner */

@keyframes roller {
    0% 	{transform: rotate(0deg);}
    100% 	{transform: rotate(360deg);}
}

.spinner-custom 								{display: inline-block; padding: 10px; margin: auto;}
.spinner-custom .roller 						{display: inline-block; position: relative; width: 64px; height: 64px;}
.spinner-custom .roller div 					{animation: roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite; transform-origin: 32px 32px;}
.spinner-custom .roller div:after 				{content: " "; display: block; position: absolute; width: 6px; height: 6px; border-radius: 50%; background: #94c73e; margin: -3px 0 0 -3px;}
.spinner-custom .roller div:nth-child(1) 		{animation-delay: -0.036s;}
.spinner-custom .roller div:nth-child(1):after 	{top: 50px; left: 50px;}
.spinner-custom .roller div:nth-child(2) 		{animation-delay: -0.072s;}
.spinner-custom .roller div:nth-child(2):after 	{top: 54px;left: 45px;}
.spinner-custom .roller div:nth-child(3) 		{animation-delay: -0.108s;}
.spinner-custom .roller div:nth-child(3):after 	{top: 57px; left: 39px;}
.spinner-custom .roller div:nth-child(4) 		{animation-delay: -0.144s;}
.spinner-custom .roller div:nth-child(4):after 	{top: 58px;left: 32px;}
.spinner-custom .roller div:nth-child(5) 		{animation-delay: -0.18s;}
.spinner-custom .roller div:nth-child(5):after 	{top: 57px; left: 25px;}
.spinner-custom .roller div:nth-child(6) 		{animation-delay: -0.216s;}
.spinner-custom .roller div:nth-child(6):after 	{top: 54px;left: 19px;}
.spinner-custom .roller div:nth-child(7) 		{animation-delay: -0.252s;}
.spinner-custom .roller div:nth-child(7):after 	{top: 50px; left: 14px}
.spinner-custom .roller div:nth-child(8) 		{animation-delay: -0.288s;}
.spinner-custom .roller div:nth-child(8):after 	{top: 45px; left: 10px;}

/* Buttons */

.btn 								{padding: .5em .825em; font-size: 1rem; font-weight: 700; text-decoration: none; border-radius: 0.2rem; line-height: 1.444;
    -webkit-transition: all 150ms ease; -moz-transition: all 150ms ease; -o-transition: all 150ms ease; transition: all 150ms ease; border: none;}
.btn:focus 							{outline: none !important; box-shadow: none !important;}
.btn:hover 							{/* transform: scale(1.0155); */}
.btn-primary 						{background-color: #94c73e !important; border-color: #94c73e !important;}
.btn-primary:hover 					{background-color: #94c73e !important; border-color: #94c73e !important;}
.btn-secondary 						{background-color: #3b3b3b !important; border-color: #3b3b3b !important;}
.btn-secondary:hover 				{background-color: #3b3b3b !important; border-color: #3b3b3b !important;}
.btn-login-custom 					{background-color: #f0582e !important; color: #fff; border-radius: 0;}
.btn-login-custom:hover				{color: #fff;}

.btn-green-custom					{background-color: #94c73e; color: #fff;
    background: -moz-linear-gradient(top, #afcc7a 0%, #94c73e 12%, #94c73e 65%, #85b136 100%);
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#afcc7a), color-stop(12%,#94c73e), color-stop(65%,#94c73e), color-stop(100%,#85b136));
    background: -webkit-linear-gradient(top, #afcc7a 0%,#94c73e 12%,#94c73e 65%,#85b136 100%);
    background: -o-linear-gradient(top, #afcc7a 0%,#94c73e 12%,#94c73e 65%,#85b136 100%);
    background: -ms-linear-gradient(top, #afcc7a 0%,#94c73e 12%,#94c73e 65%,#85b136 100%);
    background: linear-gradient(to bottom, #afcc7a 0%,#94c73e 12%,#94c73e 65%,#85b136 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#afcc7a', endColorstr='#85b136',GradientType=0 );}

.btn-green-custom:hover,
.link-container-custom:hover .btn-green-custom
{background-color: #94c73e; color: #fff;
    background-image: -webkit-gradient(linear, left top, left bottom, from(#85b136), to(#94c73c));
    background-image: -webkit-linear-gradient(top, #85b136, #94c73c);
    background-image: -moz-linear-gradient(top, #85b136, #94c73c);
    background-image: -ms-linear-gradient(top, #85b136, #94c73c);
    background-image: -o-linear-gradient(top, #85b136, #94c73c);
    background-image: linear-gradient(to bottom, #85b136, #94c73c);
    filter:progid:DXImageTransform.Microsoft.gradient(GradientType=0,startColorstr=#85b136, endColorstr=#94c73c);}

.btn-yellow-custom 					{background-color: #f2be08; color: #fff;
    background: #ffe074;
    background: -moz-linear-gradient(top, #ffe074 0%, #ffc708 12%, #ffc708 65%, #e4b106 100%);
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#ffe074), color-stop(12%,#ffc708), color-stop(65%,#ffc708), color-stop(100%,#e4b106));
    background: -webkit-linear-gradient(top, #ffe074 0%,#ffc708 12%,#ffc708 65%,#e4b106 100%);
    background: -o-linear-gradient(top, #ffe074 0%,#ffc708 12%,#ffc708 65%,#e4b106 100%);
    background: -ms-linear-gradient(top, #ffe074 0%,#ffc708 12%,#ffc708 65%,#e4b106 100%);
    background: linear-gradient(to bottom, #ffe074 0%,#ffc708 12%,#ffc708 65%,#e4b106 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffe074', endColorstr='#e4b106',GradientType=0 );}
.btn-yellow-custom:hover,
.link-container-custom:hover .btn-yellow-custom
{background-color: #f2be08; color: #fff;
    background-image: -webkit-gradient(linear, left top, left bottom, from(#e4b106), to(#ffc708));
    background-image: -webkit-linear-gradient(top, #e4b106, #ffc708);
    background-image: -moz-linear-gradient(top, #e4b106, #ffc708);
    background-image: -ms-linear-gradient(top, #e4b106, #ffc708);
    background-image: -o-linear-gradient(top,#e4b106, #ffc708);
    background-image: linear-gradient(to bottom, #e4b106, #ffc708);
    filter:progid:DXImageTransform.Microsoft.gradient(GradientType=0,startColorstr=##e4b106, endColorstr=#ffc708);}

.btn-red-custom 					{background-color: #e74f23; color: #fff;
    background: -moz-linear-gradient(top, #f69a81 0%, #f2592f 12%, #f2592f 65%, #d74f29 100%);
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#f69a81), color-stop(12%,#f2592f), color-stop(65%,#f2592f), color-stop(100%,#d74f29));
    background: -webkit-linear-gradient(top, #f69a81 0%,#f2592f 12%,#f2592f 65%,#d74f29 100%);
    background: -o-linear-gradient(top, #f69a81 0%,#f2592f 12%,#f2592f 65%,#d74f29 100%);
    background: -ms-linear-gradient(top, #f69a81 0%,#f2592f 12%,#f2592f 65%,#d74f29 100%);
    background: linear-gradient(to bottom, #f69a81 0%,#f2592f 12%,#f2592f 65%,#d74f29 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f69a81', endColorstr='#d74f29',GradientType=0 );}

.btn-red-custom:hover,
.link-container-custom:hover .btn-red-custom
{background-color: #e74f23; color: #fff;
    background-image: -webkit-gradient(linear, left top, left bottom, from(#d74f29), to(#f2592f));
    background-image: -webkit-linear-gradient(top, #d74f29, #f2592f);
    background-image: -moz-linear-gradient(top, #d74f29, #f2592f);
    background-image: -ms-linear-gradient(top, #d74f29, #f2592f);
    background-image: -o-linear-gradient(top, #d74f29, #f2592f);
    background-image: linear-gradient(to bottom, #d74f29, #f2592f);
    filter:progid:DXImageTransform.Microsoft.gradient(GradientType=0,startColorstr=#d74f29, endColorstr=#f2592f);}

.btn-gray-custom 					{background-color: #d8d8d8; color: #000; font-weight: 400;
    background: -moz-linear-gradient(top, #e1e1e1 0%, #d8d8d8 12%, #d8d8d8 65%, #bfbfbf 100%);
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#e1e1e1), color-stop(12%,#d8d8d8), color-stop(65%,#d8d8d8), color-stop(100%,#bfbfbf));
    background: -webkit-linear-gradient(top, #e1e1e1 0%,#d8d8d8 12%,#d8d8d8 65%,#bfbfbf 100%);
    background: -o-linear-gradient(top, #e1e1e1 0%,#d8d8d8 12%,d8d8d8 65%,#bfbfbf 100%);
    background: -ms-linear-gradient(top, #e1e1e1 0%,#d8d8d8 12%,#d8d8d8 65%,#bfbfbf 100%);
    background: linear-gradient(to bottom, #e1e1e1 0%,#d8d8d8 12%,#d8d8d8 65%,#bfbfbf 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e1e1e1', endColorstr='#bfbfbf',GradientType=0 );}

.btn-gray-custom:hover,
.link-container-custom:hover .btn-gray-custom
{color: #000;
    background: -moz-linear-gradient(bottom, #e1e1e1 0%, #bfbfbf 100%) !important;
    background: -webkit-gradient(linear, left bottom, left top, color-stop(0%,#e1e1e1), color-stop(100%,#bfbfbf));
    background: -webkit-linear-gradient(bottom, #e1e1e1 0%,#bfbfbf 100%);
    background: -o-linear-gradient(bottom, #e1e1e1 0%,#bfbfbf 100%);
    background: -ms-linear-gradient(bottom, #e1e1e1 0%,#bfbfbf 100%);
    background: linear-gradient(to top, #e1e1e1 0%,#bfbfbf 100%) !important;
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#bfbfbf', endColorstr='#e1e1e1',GradientType=0 );}

.btn-xl-custom						{padding: .95em .925em;}
.btn-md-custom						{padding: .95em .925em;}
.btn-sm-custom						{padding: .2em .425em; font-size: 0.785rem; font-weight: 700;}


/* Bootstrap Layer */

.modal-dialog								{max-width: 510px;}
.modal-dialog button.close					{width: auto !important; opacity: 1;}
.modal-dialog button.close:hover			{opacity: .5;}
.modal-dialog .list-group-item				{color: #3b3b3b;}
/*.modal-dialog .list-group-item:hover 		{color: #808080; background-color: #f5f5f5;}*/
.modal-content								{border-radius: 0;}
.modal-header								{border-bottom: 0;}
.modal-footer								{border-top: 0; padding-top: 0;}

.popover									{border-color: #707070; border-radius: 0; min-width: 15rem; z-index: 1020;
    -webkit-box-shadow: 0px 0px 10px 2px rgba(0,0,0,.2);
    -moz-box-shadow: 0px 0px 10px 0px rgba(0,0,0,.2);
    box-shadow: 0px 0px 10px 0px rgba(0,0,0,.2);}
.popover-body								{font-family: 'OpenSans'; font-size: 1rem;}

.bs-popover-auto[x-placement^="top"] .arrow,
.bs-popover-top .arrow 						{bottom: calc((.5rem + 0px) * -1);}

.bs-popover-auto[x-placement^="bottom"] .arrow,
.bs-popover-bottom .arrow 					{top: calc((.5rem + 0px) * -1);}

.bs-popover-auto[x-placement^="top"] .arrow::before,
.bs-popover-top .arrow::before 				{bottom: 0; border-top-color: rgba(0,0,0,.80);}


.bs-popover-auto[x-placement^="bottom"] .arrow::before,
.bs-popover-bottom .arrow::before			{top: 0; border-bottom-color: rgba(0,0,0,.80);}


/* CM Specials */

.tooltip-custom								{line-height: 1 !important;}

.box-cta-custom								{}
.box-cta-custom .btn						{width: 31% !important; padding: .25em 0;}
.layer-var01-custom .box-cta-custom .btn 	{width: 32% !important;}

.fa-width-custom							{display: inline-block; text-align: center; min-width: 1.3em;}
.fa-size-custom								{font-size: 2.075em;}
.icon-edit-custom 							{top: .05em; right: .05em;}
.progress-custom							{height: 1.825rem; font-size: 85%; font-weight: 600; background-color: #eaeaea; border-radius: 0;}
.alert-custom								{padding: .75rem 1rem; color: #fff; border-radius: 0; border: none; font-weight: 700;}

.alert-danger-custom						{padding-top: 1rem; padding-bottom: 0.25rem; background-color: #fff; border: 2px solid #f2592f; border-radius: 0;}
.alert-danger-custom p						{color: #3b3b3b;}
.alert-danger-custom hr						{border-color: #dfdfdf; }


/* Tree */

.tree-custom								{margin: 0 0 2rem 0; padding: 0;}
.tree-custom li								{margin: 0; padding: 0; list-style: none;}
.tree-custom li:last-child					{padding-bottom: 0;}
.tree-custom > li							{border-top: 1px dotted #3b3b3b; padding-top: .625rem; padding-bottom: .285rem;}
.tree-custom > li:last-child				{border-bottom: 1px dotted #3b3b3b; padding-bottom: .285rem;}
.tree-custom li	label						{font-weight: 700;}

.tree-custom ul								{margin-top: .25rem; padding-left: 2.625rem;}
.tree-custom ul li							{border-top: 1px dotted #3b3b3b; padding-top: .625rem; padding-bottom: .285rem;}


.tree-custom ul.last-ul-custom				{margin-left: 2rem;}
.tree-custom ul.last-ul-custom li			{padding: 0; border: none;}
.tree-custom ul.last-ul-custom li label		{margin-bottom: 0; font-weight: 400;}
.tree-custom ul.last-ul-custom li:last-child label {margin-bottom: .925rem;}

.tree-custom label							{}
.tree-custom .btn-green-custom				{margin: -2px 1rem .25rem 0; width: 1.575em; height: 1.575em; line-height: 1.525; font-size: 100%; padding: 0;}





/* Links */

a 											{color: #3b3b3b; text-decoration: underline; outline: none; border: none;
    -webkit-transition: opacity 200ms ease; -moz-transition: opacity 200ms ease; -o-transition: opacity 200ms ease; transition: all 200ms ease;}
a:hover										{color: #808080;}
a.text-white:hover							{color: #ececec !important;}
a:hover p									{color: #808080 !important;}
a:hover p .text-hover-custom				{color: #808080 !important;}

a,
.transition-custom,
.transition-custom p,
.transition-custom span,
.transition-custom img,
.transition-custom h2,
.transition-custom h3,
.transition-custom h4,
.transition-custom .card-block				{-webkit-transition: opacity 200ms ease; -moz-transition: opacity 200ms ease; -o-transition: opacity 200ms ease; transition: all 200ms ease;}

a.link-container-custom,
a.link-container-custom:hover p				{text-decoration: none; color: #3b3b3b;}
a.link-container-custom:hover span			{text-decoration: none; color: #3b3b3b;}
a.link-container-custom:hover h3,
a.link-container-custom:hover h3 span		{color: #888888;}
a.link-container-custom:hover .btn 			{/* transform: scale(1.1); */}

a.link-container-custom:hover p,
a.link-container-custom:hover img,
a.transition-custom:hover img				{filter: alpha(opacity=65); -ms-filter: "alpha(opacity=65)"; opacity: .65;}

.text-hover-custom:hover					{color: #888888 !important;}
.text-link-hover-custom:hover				{text-decoration: underline;}

a:hover .fas,
a.fas :hover								{filter: alpha(opacity=65); -ms-filter: "alpha(opacity=65)"; opacity: .65;
    -webkit-transition: opacity 200ms ease; -moz-transition: opacity 200ms ease; -o-transition: opacity 200ms ease; transition: all 200ms ease;}

h2 a, h3 a, h4 a 							{text-decoration: none;}
h2 a:hover, h3 a:hover, h4 a:hover			{filter: alpha(opacity=65); -ms-filter: "alpha(opacity=65)"; opacity: .65; color: inherit; text-decoration: none;}

header a,
footer a,
.no-textdecoration-custom					{text-decoration: none !important}

.no-textdecoration-custom:hover				{text-decoration: underline !important;}

a, .pointer-custom 							{cursor: pointer !important;}


/* Link Container: Link Animation */

@keyframes blink {

    0% 										{opacity: .2;}
    20% 									{opacity: 1;}
    100% 									{opacity: .2;}

}

.link-container-custom .animation-custom 							{font-size: 1.875rem; margin-top: -.25rem; margin-right: 0.15rem;}
.link-container-custom .animation-custom span:nth-child(1),
.link-container-custom .animation-custom span:nth-child(2) 			{display: none;}

.link-container-custom:hover .animation-custom 						{opacity: .75; margin-right: -0.15rem;}
.link-container-custom:hover .animation-custom span 				{display: inline-block; animation-name: blink; animation-duration: 3s; animation-iteration-count: infinite; animation-fill-mode: both;}

.link-container-custom:hover .animation-custom span:nth-child(2)	{display: inline-block; animation-delay: .3s;}
.link-container-custom:hover .animation-custom span:nth-child(3)	{animation-delay: .6s;}


/* Image Container */

.img-container-custom						{overflow: hidden; position: relative;}
.img-container-custom img					{position: absolute; left: -50%; right: -50%; top: -50%; bottom: -50%; height: 100%; width: auto; margin: auto;}

.square-lg-custom							{width: 12.75rem; height: 12.75rem; min-width: 12.75rem; min-height: 12.75rem;}
.square-md-custom							{width: 7rem; height: 7rem; min-width: 7rem; min-height: 7rem;}
.square-sm-custom							{width: 3.625rem; height: 3.625em; min-width: 3.625rem; min-height: 3.625rem;}

.rectangle-lg-custom						{width: 100%; min-width: 100%;}
.rectangle-md-custom						{}
.rectangle-sm-custom						{}


/* Borders */

.border-light-custom						{border-color: #dfdfdf !important; border-radius: 0 !important;}
.border-dark-custom							{border-color: #353535 !important; border-radius: 0 !important;}


/* Lists */

.ul											{list-style-type: square;}
.list-plain-custom							{margin: 0; padding: 0; list-style-type: none;}
.list-plain-custom li						{margin-bottom: .5rem;}


/* Tables */

.table										{border-bottom: 1px solid #dfdfdf;}
.table td, .table th						{border-color: #dfdfdf !important;}

.table-custom tr:last-child 				{border-bottom: 1px solid #dfdfdf;}

.table-var01-custom							{font-size: 95%; border-bottom: 0 !important;}
.table-var01-custom td,
.table-var01-custom th						{padding: .095rem .45rem; border: .25rem solid #fff !important; border-left: 0 !important; border-bottom: 0 !important;}
.table-var01-custom th						{border-top: 0 !important;}
.table-var01-custom tr td:first-child 		{width: 9.5rem;}

.table-var02-custom							{border-bottom: 0 !important;}
.table-var02-custom th						{border: 0 !important;}
.table-var02-custom tr:last-child 			{border-bottom: 1px solid #dfdfdf;}
.table-var02-custom .m-height-custom 		{min-height: 3.75rem;}


/* Layer */

.layer-var01-custom							{top: -9em; left: -100%; right: -100%; width: 15rem; font-size: 1rem; z-index: 1;}

.layer-var01-custom:after,
.layer-var01-custom:before					{top: 100%; left: 50%; border: solid transparent; content: " "; height: 0; width: 0; position: absolute; pointer-events: none;}

.layer-var01-custom:after 					{border-color: rgba(255, 255, 255, 0); border-top-color: #fff; border-width: 15px; margin-left: -15px;}
.layer-var01-custom:before 					{border-color: rgba(53, 53, 53, 0); border-top-color: #353535; border-width: 16px; margin-left: -16px;}


/* Forms */

textarea,
select,
input[type="text"],
input[type="email"],
input[type="password"],
input[type="tel"],
input[type="url"],
input[type="date"],
input[type="time"]							{-webkit-appearance: none !important; -moz-appearance: none !important; appearance: none !important;
    border-radius: 0 !important; -webkit-border-radius: 0 !important; -moz-border-radius: 0 !important;
    font-family: 'OpenSans'; font-weight: 400; border: 1px solid #757575 !important; background-color: #fff;}

button 										{margin: 0; padding: .75rem .4rem .75rem .4rem; font-size: 100%; line-height: 1.5;
    font-family: 'OpenSans'; font-weight: 400;}
textarea:focus,
select:focus,
input:focus									{border-color: #4498ba !important;}

select 										{background: url(/img/icon_select.png) right center no-repeat;
    background-size: 2.25rem auto; background-repeat: no-repeat; padding-right: 2.25rem !important;}
select[multiple] 							{height: auto; background-image: none;}


button										{color: #fff; background: #94c73e; cursor: pointer;}
button:hover								{-webkit-transition: opacity 200ms ease; -moz-transition: opacity 200ms ease; -o-transition: opacity 200ms ease; transition: all 200ms ease;}

input[type="submit"]						{padding: .65rem 1rem .625rem 1rem; font-weight: 700; color: #fff; background: #94c73e; cursor: pointer; border: 0; width: auto; border: none;
    -webkit-transition: opacity 200ms ease; -moz-transition: opacity 200ms ease; -o-transition: opacity 200ms ease; transition: all 200ms ease;}
input[type="submit"]:hover					{background: #a1cf55;}
input[type="submit"]:focus					{border: none;}

input[type="checkbox"],
input[type="radio"] 						{width: auto;}

.input-date-placeholder-custom				{position: relative;}
.input-date-placeholder-custom::after		{position: absolute; right: .45em; top: 0.145em; font-size: 1.3em; font-family: 'fontello';
    color: #97c73d; content: "\f133"; pointer-events: none;}
.input-date-placeholder-custom input		{min-height: 2.25em;}

.input-time-placeholder-custom				{position: relative;}
.input-time-placeholder-custom::after		{position: absolute; right: .45em; top: 0.145em; font-size: 1.3em; font-family: 'fontello';
    color: #97c73d; content: "\e800"; pointer-events: none;}
.input-time-placeholder-custom input		{min-height: 2.25em;}


/* Wrapper + Spacer */

.wrapper-custom								{margin: 0 auto; position: relative;}
.spacer-intro-custom						{border-bottom: 5px solid #fff; width: 5.25rem;}
.spacer-bg-custom							{height: 80px;}
.spacer-custom-var01						{height: 4px; background-color: #3b3b3b;}
.spacer-custom-var02						{height: 7px; background-color: #3b3b3b;}
.spacer-custom-var03						{height: 1px; border-top: 1px dotted #707070;}

.wrapper-custom.pseudo-element:before 		{display: block; content: ''; background: #3b3b3b; height: 720px; margin-top: -720px; margin-left: 0px;}


/* Custom Sizings */

.mw-70-custom								{min-width: 70%;}
.mw-60-custom								{min-width:60%;}
.mw-40-custom								{min-width: 70%;}
.mw-30-custom								{min-width: 30%;}
.mw-66-custom								{min-width: 66.5%;}
.mw-33-custom								{min-width: 33.5%;}

.w-30-custom								{width: 30%;}
.w-35-custom								{width: 35%;}
.w-40-custom								{width: 40%;}
.w-60-custom								{width: 60%;}
.w-70-custom								{width: 70%;}
.w-85-custom								{width: 85%;}


/* Navbar */

.counter-custom								{display: inline-block; min-width: 2.05em; height: 2.05em; margin: -.5em -.375em -50% 0; padding: 0 .5em 0 .5em; vertical-align: top; border-radius: 3em;
    line-height: .15em; text-align: center;}
.counter-custom span						{line-height: 2; color: #fff !important;}
.navbar-counter-custom						{margin: .175rem 1.25rem 0 0; font-size: 95%;}

.navbar-brand-custom						{position: absolute; left: 0; top: 0;}
.navbar-brand-custom canvas					{display: block; width: 100%; visibility: hidden;}
.navbar-brand-custom img					{position: absolute; top: -50%; bottom: -50%; width: auto; height: 100%; margin: auto 0 auto 0;}
.minified-custom .navbar-brand-custom img	{width: auto !important;}

.main-nav-custom .nav-item .nav-link		{font-weight: 600; color: #fff; white-space: nowrap; border-bottom: 5px solid transparent;}
.main-nav-custom .nav-item .nav-link:hover,
.main-nav-custom .nav-item .nav-link.active,
.main-nav-custom .nav-item.show .nav-link	{color: #b5b5b5; border-bottom: 5px solid #fff;}
.main-nav-custom .nav-item .nav-link.active	{color: #fff;}
.main-nav-custom .nav-item.show .nav-link	{color: #b5b5b5; border-bottom: 5px solid #fff;}
.main-nav-custom .btn						{font-size: 1rem; padding: 0.4rem 0.825rem;}

.meta-nav-custom .nav-item .nav-link		{font-weight: 400; color: #fff; white-space: nowrap;}
.meta-nav-custom .nav-item .nav-link:hover,
.meta-nav-custom .nav-item .nav-link.active,
.meta-nav-custom .nav-item.show .nav-link	{color: #b5b5b5;}
.meta-nav-custom .nav-item .nav-link-custom	{display: inline-block; padding-left: 1rem !important; margin-left: .5rem; border-left: 1px solid #7c7c7c;}
.meta-nav-custom .nav-item.user-custom span	{width: 100%; white-space: nowrap; overflow: hidden; margin-bottom: -0.125em;}

.meta-nav-custom .btn-login-custom			{padding: 0.275em 0.65em; margin-left: .5rem;}
.meta-nav-custom .btn-login-custom:hover 	{transform: scale(1.0555);}


/* Cards */

.card-header								{border: none; padding: 1rem 0 .5rem 0;}
.card-header .img-container-custom			{border: none;}


/* Accordion and collapsing blocks */

.hide-on-toggle-custom:not(.collapsed)							{display :none;}

.link-collapse-custom:not(.collapsed) .btn > i.fa-angle-right 	{display: none;}
.link-collapse-custom:not(.collapsed) .btn > i.fa-angle-down 	{display: inline-block !important;}
.btn.link-collapse-custom:not(.collapsed) > i.fa-angle-right 	{display: none;}
.btn.link-collapse-custom:not(.collapsed) > i.fa-angle-down 	{display: inline-block !important;}

.filter-collapse-custom:not(.collapsed)							{border-bottom: 1px solid #fff !important;}
.filter-collapse-custom:not(.collapsed) .btn > i.fa-angle-right {display: none;}
.filter-collapse-custom:not(.collapsed) .btn > i.fa-angle-down 	{display: inline-block !important;}

.filter-container-custom										{margin-top: -1px;}


/* Google Maps */

.map-responsive-custom						{overflow: hidden;}
.map-responsive-custom iframe				{width: 100%; height: 14rem !important;}


/* Footer */

footer										{display: block; position: relative; padding-bottom: 8rem !important;
    background-image: url('../img/bg_footer.png'); background-size: auto 4.5rem; background-position: center bottom; background-repeat: repeat-x;}
footer p,
footer a									{font-weight: 200; letter-spacing: 0.015rem;}
footer a.link-top-custom					{display: none; position: absolute; top: -4rem; right: -50%; left: -50%; width: 3.25rem; height: 3.25rem; padding: 0;
    background: url('../img/control_top-x.png') center center no-repeat; background-size: auto 50%; z-index: 1;}
footer a.link-top-custom:hover				{}
footer a.link-top-custom span				{display: none;}

footer .container							{}

/* Attendance List Box Checkbox */
.attendance-checkboxes{
    align-items: center;
    background: #e6e6e6;
    margin-bottom: 4px;
    width: 100%;
    vertical-align: middle;
}
.attendance-checkboxes div {
    margin-top: 1px;
    margin-bottom: 1px;
}
.attendance-checkboxes label {
    display: block;
    margin-right: 10px;
    margin-left: 32px;
    text-indent: -22px;
}
.option-yes{
    margin-top: 5px;
}
.option-no{
    margin-top: -15px;
    margin-bottom: 5px;
}
.attendance-checkboxes input {
    vertical-align: middle;
    padding: 0.3em;
    -webkit-appearance: none;
    outline: 0.1em solid black;
    outline-offset: 0.1em;
    background-color: white;
}
.attendance-checkboxes input:checked {
    display: inline-block;
    background-color: green;
}
.attendance-checkboxes label span {
    vertical-align: middle;
    padding-left: 15px;
}

/* ---------------------------------------------------------------------------------------------
Media queries
--------------------------------------------------------------------------------------------- */

/* Max widths */

@media (max-width: 991px){

    h3, .h3														{font-size: 1.5rem;}
    footer .row-custom p										{font-size: .875rem;}
    footer .row-custom nav										{font-size: .75rem;}

}

@media (max-width: 767px){

    html														{font-size: 18px;}
    /*body														{padding-top: 60px;}*/

    h1, .h1														{font-size: 2rem;}
    h2, .h2														{font-size: 1.5rem;}
    h3, .h3														{font-size: 1.25rem;}
    h4, .h4														{font-size: 1rem;}

    .navbar														{padding: 13px 0 9px 0;}
    .navbar-brand-custom										{height: 56px; width: 145px; position: absolute; left: -50%; right: -50%; top: 3px; margin: auto;text-align: center;}
    .navbar-brand-custom img									{left: -50%; right: -50%; margin: auto;}

    .brand-center-custom 										{padding: 0.25em 0 0.25em 0;}
    .brand-center-custom img									{height: 3.125rem;}

    .navbar-toggler												{margin-left: .375rem; width: auto; border: none !important;}
    .navbar-toggler-icon										{background: url('../img/toggler.png') center center no-repeat !important; background-size: 100% auto !important;}
    .navbar-collapse											{margin-top: .5rem; padding: 0; background-color: #3b3b3b;}
    .navbar-collapse.show										{margin-bottom: -.5rem; height: 100vh; overflow-y: auto;}
    .navbar-collapse .nav-item									{padding: 0 1.125rem 0 1.125rem; font-size: 1rem;}

    .navbar-collapse .main-nav-custom							{padding: 1.375rem 0 2rem 0; background: #525252;}
    .navbar-collapse .main-nav-custom .nav-item					{font-size: 1.25rem; padding-bottom: .25rem;}
    .navbar-collapse .main-nav-custom .nav-item .nav-link		{display: inline-block;}
    .navbar-collapse .main-nav-custom .btn 						{margin-top: 1rem; min-width: 14rem; font-size: 1.125rem;}

    .navbar-collapse .meta-nav-custom .fa-custom .nav-link		{width: 2.5rem;}
    .navbar-collapse .meta-nav-custom .fa-custom .nav-link .fa 	{font-size: 1.25rem;}

    .navbar-collapse .meta-nav-custom 							{padding: 1rem 0 10rem 0; background-image: url('../img/bg_footer.png');
        background-size: auto 3.825rem; background-position: left 85%; background-repeat: repeat-x;}
    .navbar-collapse .meta-nav-custom .nav-item .nav-link-custom{padding-left: 0 !important; margin-left: 0; border-left: 0;}

    .navbar-collapse .nav-item .nav-link-custom .counter-custom {margin: .05rem -.625rem 0 -.2rem; font-size: 75%;}

    .anchor-aim-custom a										{top: -5.5rem;}
    .center-custom												{text-align: left !important;}
    .tooltip-custom												{margin-top: -.5rem; margin-right: -.25rem;}
    .fa-size-custom												{font-size: 1.75em;}


    .table-custom tr											{border-top: 1px solid #dfdfdf;}
    .table-custom tr td											{display: block; width: 100% !important; border-width: 0 !important;}
    .table-custom tr td:last-child								{padding-bottom: .5rem;}

    .table-var01-custom											{font-size: 80%;}
    .table-var01-custom tr td:last-child,
    .table-var01-custom tr th:last-child						{border-right: 0 !important;}

    .table-var01-custom tr td:first-child 						{width:3rem;}
    .table-var01-custom tr td:first-child .responsive-custom	{display: none;}

    .table-var02-custom tr										{display: block; margin-top: 3rem; border-width: 0 !important;}
    .table-var02-custom thead tr								{margin-top: 0; padding-bottom: 1.25rem;}
    .table-var02-custom tr th									{display: block; width: 100% !important; padding: 1rem 0 0 1rem !important; font-size: 1rem;}
    .table-var02-custom tr td									{display: block; width: 100% !important; border-width: 0;}

    .table-var02-custom tr td:nth-child(1),
    .table-var02-custom tr td:nth-child(2)						{width: 50% !important; float: left; border-width: 1px;}
    .table-var02-custom tr td:nth-child(2)						{text-align: right; padding-top: .5rem;}
    .table-var02-custom tr td:nth-child(3)						{clear: both;}
    .table-var02-custom tr td:last-child						{border-bottom-width: 1px; border-style: solid;}

    .table-var02-custom .fa-user,
    .container-var01-custom .fa-user,
    .container-var04-custom .fa-user							{font-size: 1.75rem;}

    .filter-container-custom .small,
    .table-var02-custom .small,
    .container-var04-custom .small								{font-size: 100%;}

    .container-mobile-w100-custom								{max-width: 100%; width: 100%}

    .rectangle-lg-custom										{height: 15rem; min-height: 15rem;}
    .rectangle-md-custom										{}
    .rectangle-sm-custom										{}

    .square-lg-custom											{width: 10rem; min-width: 10rem; height: 10rem; min-height: 10rem;}
    .square-md-custom											{}
    .square-sm-custom											{}

    .tree-custom												{font-size: 95%;}
    .tree-custom ul												{padding-left: 1.5rem;}
    .tree-custom ul.last-ul-custom								{padding-left: .125rem;}
    .tree-custom .btn-green-custom								{margin-right: .5em;}

    input[type="submit"],
    button														{width: 100%;}


    footer														{background-size: auto 4.5rem; padding-bottom: 6rem !important;}
    footer .row-custom											{display: none;}
    footer .line-height-custom									{line-height: 2.222;}
    footer .col-fa-custom										{text-align: center;}
    footer .col-fa-custom a										{margin-left: .75rem !important;; margin-right: .75rem !important;}
    footer .copyright-custom									{margin: 0.25rem 0 0 0; text-align: center; font-size: 90%;}
    footer .row.my-2											{margin: 0 !important;}
    footer a.link-top-custom									{display: block; margin: 0 auto;}

}


@media (max-width: 420px){

    html														{font-size: 16px;}
    .btn-xl-custom												{width: 100%}
    .table-var01-custom											{font-size: 90%; overflow: hidden;}

}


@media (max-width: 480px){

    .table-var01-custom											{font-size: 100%; overflow: hidden;}
    .table-var01-custom,
    .table-var01-custom thead,
    .table-var01-custom tbody,
    .table-var01-custom tr,
    .table-var01-custom th,
    .table-var01-custom td										{display: block; width: 100% !important;}

    .table-var01-custom,
    .table-var01-custom td 										{text-align: right;}
    .table-var01-custom thead 									{position: absolute; top: -1111px; left: -1111px;}

    .table-var01-custom td 										{width: 25% !important; float: left;}
    .table-var01-custom td:before 								{float: left; content: attr(data-label) ":";}
    .table-var01-custom td:first-child		 					{width: 100% !important; border-right: 0 !important;}
    .table-var01-custom td:nth-child(4n+5)		 				{border-right: 0 !important;}
    .table-var01-custom td:first-child:before 					{content: attr(data-label) " ";}
    .table-var01-custom tr td:first-child .responsive-custom	{display: block;}

    .rectangle-lg-custom										{height: 13rem; min-height: 13rem;}
    .rectangle-md-custom										{}
    .rectangle-sm-custom										{}


}


/* Min widths */

@media (min-width: 577px){

    .rectangle-lg-custom										{height: 15rem; min-height: 15rem;}
    .rectangle-md-custom										{}
    .rectangle-sm-custom										{}

}

@media (min-width: 768px){

    html 														{font-size: 16px;}
    /*body 														{padding-top: 6.7rem;}*/

    .modal-dialog												{max-width: 698px;}

    .navbar	 													{padding: 2.5rem 0 0 0;}
    .navbar .navbar-collapse									{/* justify-content: flex-end; */}

    .navbar-brand-custom										{top: 12px; height: 85px;}
    .navbar-brand-custom img									{}

    .brand-center-custom 										{padding: 1em 0 0.55em 0;}
    .brand-center-custom img									{height: 5.275rem;}
    .minified-custom .brand-center-custom						{padding: 0.25em 0 0.2em 0;}
    .minified-custom .brand-center-custom img					{height: 3.25rem;}

    .meta-nav-custom 											{display: block; position: absolute; right: 0; top: 1rem; padding-right: .75rem;}
    .meta-nav-custom .nav-item 									{display: inline-block;}
    .meta-nav-custom .nav-item .nav-link						{padding: .25em .325em; font-size: .75rem; line-height: 1;}
    .meta-nav-custom .nav-item.user-custom span					{max-width: 10.125rem;}
    .meta-nav-custom .nav-item.display-custom 					{display: none;}
    .meta-nav-custom .btn-login-custom							{font-size: .75rem;}

    .main-nav-custom .nav-item									{margin-left: .9rem;}
    .main-nav-custom .nav-item .nav-link						{padding: .825rem .25rem 0 .25rem; font-size: .9rem; line-height: 3.5;}
    .main-nav-custom .btn										{margin: 1.25rem 0 0 0; font-size: .825rem; padding: 0.45rem 0.5rem;}

    .minified-transition-custom									{-webkit-transition: opacity 200ms ease; -moz-transition: opacity 200ms ease; transition: all 200ms ease;}
    .minified-custom											{-webkit-box-shadow: 0px 0px 17px -7px rgba(0,0,0,.95); -moz-box-shadow: 0px 0px 17px -7px rgba(0,0,0,.95); box-shadow: 0px 0px 17px -7px rgba(0,0,0,.95);}
    .minified-custom .navbar									{padding-top: .5rem;}
    .minified-custom .navbar-brand-custom						{height: 2.75rem; width: auto; top: .5rem;}
    .minified-custom .meta-nav-custom							{display: none;}
    .minified-custom .button-nav-custom							{display: none;}
    .minified-custom .main-nav-custom .nav-item .nav-link		{padding: 0 .125rem 0 .125rem; line-height: 3;}
    .minified-custom .main-nav-custom .btn						{margin-top: 0.125rem; margin-bottom: 0;}

    .anchor-aim-custom a										{top: -5rem;}

    .btn-xl-custom												{font-size: 1.075rem;}

    footer														{display: block; position: relative;}
    footer p													{font-size: .925rem;}
    footer .line-height-custom									{font-size: .925rem; line-height: 2.222;}
    footer .copyright-custom									{margin: .75rem 0 0 0; text-align: right;}
    footer .col-fa-custom										{margin-top: .5rem;}

}


@media (min-width: 992px){

    html 														{font-size: 16px;}
    /*body 														{padding-top: 7.75rem;}*/

    .modal-dialog												{max-width: 930px;}

    .navbar	 													{}
    .navbar .navbar-collapse									{justify-content: center;}
    .navbar-brand-custom										{top: 13px; height: 104px;}
    .navbar-brand-custom img									{}

    .brand-center-custom 										{padding: 0.675em 0 0.55em 0;}
    .brand-center-custom img									{height: 6.5rem;}
    .minified-custom .brand-center-custom						{padding: 0.25em 0 0.2em 0;}
    .minified-custom .brand-center-custom img					{height: 3.25rem;}

    .meta-nav-custom 											{top: 1rem; padding-right: 1rem;}
    .meta-nav-custom .nav-item .nav-link						{padding: .15rem .375rem; font-size: .75rem;}
    .meta-nav-custom .nav-item.user-custom span					{max-width: 10rem;}
    .meta-nav-custom .btn-login-custom							{font-size: .825rem;}

    .main-nav-custom .nav-item									{margin-left: 1.625rem;}
    .main-nav-custom .nav-item .nav-link						{padding: 1.5rem .125rem 0 .125rem; font-size: 1rem;}
    .main-nav-custom .btn										{margin-top: 2rem; font-size: 1rem; padding: 0.45rem 0.825rem;}

    .minified-custom .navbar-brand-custom						{height: 3.25rem; width: auto; top: .25rem;}
    .minified-custom .main-nav-custom .btn						{margin-top: 0.35rem;}


    .scale-custom												{-webkit-transition: opacity 200ms ease; -moz-transition: opacity 200ms ease; -o-transition: opacity 200ms ease; transition: all 200ms ease;}
    .scale-custom:hover											{transform: scale(1.0111);}

    .nav-link:hover .counter-custom								{transform: scale(1.15);}

    .btn-xl-custom												{font-size: 1.225rem;}

    .layer-var01-custom											{width: 16rem;}

    footer p 													{font-size: 1rem;}

}

@media (min-width: 1140px){

    html 														{font-size: 16px;}
    /*body 														{padding-top: 145px;}*/

    .modal-dialog												{max-width: 930px;}

    .tel-custom													{text-decoration: none; pointer-events: none; cursor: default; border: none !important;}

    .navbar	 													{padding: 57px 0 0 0;}
    .navbar .navbar-collapse									{justify-content: center;}
    .navbar-brand-custom										{top: 10px; height: 128px;}

    .brand-center-custom img									{height: 7.625rem;}
    .minified-custom .brand-center-custom img					{height: 3.25rem;}

    .meta-nav-custom 											{padding-right: 1.5rem}
    .meta-nav-custom .nav-item .nav-link						{padding: .15rem .375rem; font-size: .825rem;}

    .main-nav-custom .nav-item									{margin-left: 2.25rem;}
    .main-nav-custom .nav-item .nav-link						{font-size: 1.125rem;}
    .main-nav-custom .btn										{margin: 2.25rem 0 .75rem 0;}

    .minified-custom .navbar									{padding-top: 0.25rem;}
    .minified-custom .main-nav-custom .nav-item .nav-link		{line-height: 2.777;}

    .anchor-aim-custom a										{top: -5.5rem;}

    .rectangle-lg-custom										{height: 16.5rem; min-height: 16.5rem;}
    .rectangle-md-custom										{}
    .rectangle-sm-custom										{}

    .form-check-input											{margin-top: .415em;}

}


@media (min-width: 1200px){

    .modal-dialog												{max-width: 1110px;}
    .meta-nav-custom .nav-item.user-custom span					{max-width: 16rem;}

}

@media (min-width: 1700px){

    html 														{font-size: 19px;}
    /*body 														{padding-top: 165px;}*/

    .navbar	 													{padding: 60px 0 0 0;}
    .navbar-brand-custom										{top: 12px; height: 144px;}
    .button-nav-custom											{right: 2rem;}

    .meta-nav-custom .nav-item.user-custom span					{max-width: 32rem;}

    .sidebar-custom	.small										{font-size: 85%}

    .rectangle-lg-custom										{height: 13.675rem; min-height: 13.675rem;}
    .rectangle-md-custom										{}
    .rectangle-sm-custom										{}

}


@media only screen and (max-width: 768px) {
    [class*="col-"]:not(:last-child){
        margin-bottom: 16px;
    }
}