.virus_status.pending_scan {
    color: lightgray;
}

.virus_status.virus_warning {
    color: orangered;
}

.virus_status.clean {
    color: lightgreen;
}